import { PageElementQuestion } from './questionnaire'

export interface KaseReviewData {
  answers: KaseReviewAnswer[]
  sections: KaseReviewSection[]
  details: {
    names: string
    kase_product: string
    kase_type: string
  }
  id: string
  review_type: string
}

export interface KaseReviewSection {
  id: number
  name: string
}

export interface KaseReviewAnswer {
  fields: KaseReviewField[]
  section_title: string
}

export type KaseReviewField =
  | AddressHistories
  | EmploymentHistories
  | QuestionField

export interface AddressHistories {
  address_histories: AddressHistoryField[]
}

export interface AddressHistoryField {
  field_name: string
  field_path: string
  field_type: string
  field_value: AddressHistory
  editable_answers: EditableAnswer[]
  page_id: number
}

export interface AddressHistory {
  address: Address
  address_history_mismatch: boolean
  end_date: string | null
  id: number
  intended_address: boolean
  kase_id: string
  mailing_address: boolean
  owner: string
  physical_address_abroad: boolean
  shared: boolean
  spouse_end_date: string | null
  spouse_start_date: string
  start_date: string
}

export interface Address {
  autofilled: boolean
  city: string
  country_code: string
  in_care_of: string | null
  no_in_care_of: boolean
  postal_code: string
  province: string
  street: string
  unit_number: string
  unit_type: string
}

export interface EmploymentHistories {
  employment_histories: EmploymentHistoryField[]
}

export interface EmploymentHistoryField {
  field_name: string
  field_path: string
  field_type: string
  field_value: EmploymentHistory
  editable_answers: EditableAnswer[]
  page_id: number
}

export interface EmploymentHistory {
  address: Address
  address_history_mismatch: boolean
  current: boolean
  employer_name: string
  end_date: string | null
  id: number
  job_title: string
  kase_id: string
  remote: boolean
  self_employed: boolean
  start_date: string
  unemployed: boolean
}

export interface QuestionField {
  field_name: string
  field_path: string
  field_type: string
  field_value: any
  editable_answers: EditableAnswer[]
  page_id: number
}

export const isAddressHistoryField = (
  reviewField: KaseReviewField
): reviewField is AddressHistories => {
  return (reviewField as AddressHistories).address_histories !== undefined
}

export const isEmploymentHistoryField = (
  reviewField: KaseReviewField
): reviewField is EmploymentHistories => {
  return (reviewField as EmploymentHistories).employment_histories !== undefined
}

export const isQuestionField = (
  reviewField: KaseReviewField
): reviewField is QuestionField => {
  return (reviewField as QuestionField).page_id !== undefined
}

export interface EditableAnswer {
  answer_id: number
  answer_text: string | null
  question: PageElementQuestion
  question_text: string
  visible: boolean
  required: boolean
}

export interface InputOption {
  key: string
  value: string
}
